.styles-module__NG8orG__wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto 0;
  display: flex;
}

.styles-module__NG8orG__spinning {
  color: var(--wcom-c-yellow);
  animation: 3s linear infinite styles-module__NG8orG__loading-spinner;
}

@keyframes styles-module__NG8orG__loading-spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

